<template>
  <div class="page_bg" style="margin:0;" name="list">
    <!-- 顶部搜索和banner -->
    <div class="index_search_banner">
      <div class="flex-c-c-c">
        <div class="index_search flex-r-c-c">
          <van-search v-model="searchValue" class="classify_ser" placeholder="请输入搜索关键词" readonly shape="round"
                      @click="$router.push('/product/searchpro')"
          >
            <!-- <template #right-icon>
              <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem">搜索
              </van-button>
            </template> -->
          </van-search>
          <div class="allClassify_btn flex-r-c-c" @click="jumClassfly">
            <van-image width="0.48rem" height="0.48rem" fit="fill"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/serAll.png"
            />分类
          </div>
        </div>
        <!-- 搜索标签 -->
        <div class="index_serch_btn">
          <div class="serch_btn_items  ">
            <span v-for="(hot,hot_index) in hotList" :key="hot_index" class="btn_item"
                  @click="tag_ser_click(hot)"
            >{{ hot.search }}</span>
          </div>
        </div>
      </div>
      <!-- banner -->
      <div class="index_banner ">
        <van-swipe class="my-swipe" autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(banner,index_banner) in info.banners" :key="index_banner">
            <van-image width="9.33rem" height="3.47rem" :src="banner.imageUrl" fit="fill" @click="jumpSale(banner)" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="index_banner_btns flex-r-sa-c">
        <div class="banner_btn flex-r-c-c">
          <van-image width="0.32rem" height="0.32rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/banner_btn01.png"
          />
          <span class="btn_msg">海量好物</span>
        </div>
        <div class="banner_btn flex-r-c-c">
          <van-image width="0.32rem" height="0.32rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/banner_btn02.png"
          />
          <span class="btn_msg">正品低价</span>
        </div>
        <div class="banner_btn flex-r-c-c">
          <van-image width="0.32rem" height="0.32rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/banner_btn03.png"
          />
          <span class="btn_msg">顺丰快递</span>
        </div>
        <div class="banner_btn flex-r-c-c">
          <van-image width="0.32rem" height="0.32rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/banner_btn04.png"
          />
          <span class="btn_msg">当天发货</span>
        </div>
      </div>
    </div>
    <!-- 品牌区域 -->
    <div v-if="classFlyList.length>0" class="classify_brand  flex-r-s-c">
      <div v-for="(classify,classify_index) in classFlyList" :key="classify_index" class="classify_brand_item"
           @click="jumpSale(classify)"
      >
        <van-image width="1.17rem" height="1.17rem" :src="classify.imageUrl" />
        <div class="classify_name">{{ classify.name }}</div>
      </div>
    </div>
    <!-- 新人特惠 -->
    <div v-if="freshmanFlag && freshmanList.length>0" class="index_new">
      <!-- <div class="index_new"> -->
      <div class="index_new_title">
        <van-image width="4.19rem" height="0.45rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/index_new_title.png"
        />
      </div>
      <div class="index_new_list flex-r-s-s">
        <div v-for="(index_new, indexindex_new) in freshmanList" :key="indexindex_new" class="index_new_box"
             @click="stockTo(index_new)"
        >
          <van-image width="2.67rem" height="2rem" :src="index_new.picUrl" mode="aspectFit" />
          <div class="index_new_pro_name van-ellipsis">{{ index_new.name }}</div>
          <div class="index_new_pro_old">原价￥{{ index_new.originPrice }}</div>
          <div class="index_new_pro_new">
            <van-image width="0.64rem" height="0.37rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/new_tips.png"
            />
            <!-- <span>￥{{ $utils.moneyDote(index_new.price)[0] }}</span><span>{{ $utils.moneyDote(index_new.price)[1] }}</span> -->
            <span>￥{{ index_new.price }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 秒杀区域 -->
    <div v-if="scekillL && scekillL.length>0" class="home_seckill">
      <div class="home_seckill_top flex-r-sb-c">
        <div />
        <div />
        <div />
        <div class="seckill_top_time">
          <van-count-down :time="scekillL[0].time" format="DD天 HH:mm:ss"
                          style="color:#fff;font-weight:bold;font-size:0.48rem"
          >
            <template #default="timeData">
              <div class="timeDataStyle flex-r-s-c" style="font-size:0.37rem">
                <div v-if="timeData.days" class="block">{{ timeData.days }}</div>
                <div v-if="timeData.days" class="colon">天</div>
                <div class="block">{{ timeData.hours }}</div>
                <div class="colon">时</div>
                <div class="block">{{ timeData.minutes }}</div>
                <div class="colon">分</div>
                <div class="block">{{ timeData.seconds }}</div>
                <div class="colon">秒</div>
              </div>
            </template>
          </van-count-down>
        </div>
        <div class="seckill_top_more" @click="(e) => { $router.push('/seckill/seckillList') }">更多秒杀
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="home_seckill_main flex-r-s-c">
        <div v-for="(item_seckill,index_seckill) in scekillL" :key="index_seckill" style="margin-right:10upx"
             class="home_seckill_pro " @click="scekillJump(item_seckill)"
        >
          <van-image width="2.56rem" height="1.92rem" :src="item_seckill.flashPic" />
          <div class="seckill_pro_name van-ellipsis">{{ item_seckill.productName }}</div>
          <div class="seckill_pro_info van-ellipsis">{{ item_seckill.brand }} | {{ item_seckill.model }}</div>
          <div class="seckill_pro_money flex-r-sb-c">
            <div class="pro_money">￥{{ item_seckill.flashPrice }}</div>
            <div class="pro_icon" style="margin-right:0.1rem">
              <van-image width="0.53rem" height="0.53rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/qiang.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 超值通货 -->
    <div class="index_new2">
      <!-- <div class="index_new"> -->
      <div class="index_new_title flex-r-sb-c" style="height: 1.2rem;">
        <div />
        <div style="font-size: 0.32rem;color: #333;" @click="jumpTagList('282')">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="index_new_list flex-r-s-s">
        <van-swipe class="my-swipe" autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="(tag285,index_tag285) in tageIdList285" :key="index_tag285">
            <div class="flex-r-sa-c" style="flex-wrap: wrap;">
              <div v-for="(tag285_item,index_tag285_item) in tag285" :key="index_tag285_item"
                   class="tag285_item flex-r-s-c" @click.stop="jumpjianlou(tag285_item)"
              >
                <van-image width="1.6rem" height="1.6rem" :src="tag285_item.proIconUrl" />
                <div class="item_info">
                  <div class="name van-ellipsis">{{ tag285_item.proName }}</div>
                  <div class="model van-ellipsis">{{ tag285_item.proModel }}</div>
                  <div class="price"><span style="font-size: 0.21rem;">￥</span>{{ tag285_item.proPrice }}</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <!-- 严选 实惠自用 -->
    <div class="tagIdBox tagIdBox_bg01">
      <div class="tag_top flex-r-sb-c">
        <div />
        <div />
        <div class="tips">直降采购成本</div>
        <div style="font-size: 0.32rem;color: #fff;margin-right: 0.1rem;" @click="jumpTagList('281')">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="tag_list">
        <van-swipe class="my-swipe" :autoplay="false" :show-indicators="true">
          <van-swipe-item v-for="(tag281,index_tag281) in tageIdList281" :key="index_tag281"
                          style="margin-bottom: 0.3rem;"
          >
            <div class="flex-r-sa-c" style="flex-wrap: wrap;">
              <van-col v-for="(tag281_item,index_tag281_item) in tag281" :key="index_tag281_item" :span="8">
                <div class="tag281_item flex-c-c-c" @click.stop="jumpjianlou(tag281_item)">
                  <van-image width="2.67rem" height="2.67rem" :src="tag281_item.proIconUrl" />
                  <div class="item_info van-multi-ellipsis--l2">
                    <div class="name van-multi-ellipsis--l2">{{ tag281_item.proName }}</div>
                    <div class="model van-ellipsis">{{ tag281_item.proBrand }} | {{ tag281_item.proModel }}</div>
                  </div>
                  <div class="item_price flex-r-sb-c">
                    <div class="price"><span style="font-size: 0.21rem;">￥</span>{{ tag281_item.proPrice }}</div>
                    <van-icon name="shopping-cart" size="0.5rem" color="rgb(7, 103, 171)"
                              @click.stop="toCart(tag281_item)"
                    />
                  </div>
                </div>
              </van-col>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <!-- 严选 零售创收 -->
    <div class="tagIdBox tagIdBox_bg02">
      <div class="tag_top flex-r-sb-c">
        <div />
        <div />
        <div class="tips">直涨店铺收入</div>
        <div style="font-size: 0.32rem;color: #fff;margin-right: 0.1rem;" @click="jumpTagList('275')">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="tag_list">
        <van-swipe class="my-swipe" :autoplay="false" :show-indicators="true">
          <van-swipe-item v-for="(tag275,index_tag275) in tageIdList275" :key="index_tag275"
                          style="margin-bottom: 0.3rem;"
          >
            <div class="flex-r-sa-c" style="flex-wrap: wrap;">
              <van-col v-for="(tag275_item,index_tag275_item) in tag275" :key="index_tag275_item" :span="8">
                <div class="tag281_item flex-c-c-c" @click.stop="jumpjianlou(tag275_item)">
                  <van-image width="2.67rem" height="2.67rem" :src="tag275_item.proIconUrl" />
                  <div class="item_info van-multi-ellipsis--l2">
                    <div class="name van-multi-ellipsis--l2">{{ tag275_item.proName }}</div>
                    <div class="model van-ellipsis">{{ tag275_item.proBrand }} | {{ tag275_item.proModel }}</div>
                  </div>
                  <div class="item_price flex-r-sb-c">
                    <div class="price"><span style="font-size: 0.21rem;">￥</span>{{ tag275_item.proPrice }}</div>
                    <van-icon name="shopping-cart" size="0.5rem" color="rgb(7, 103, 171)"
                              @click.stop="toCart(tag275_item)"
                    />
                  </div>
                </div>
              </van-col>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <!-- 捡漏 -->
    <div class="tagIdBox tagIdBox_bg03">
      <div class="tag_top flex-r-sb-c">
        <div />
        <div />
        <div class="tips" style="margin-left:-1.5rem">包装微损/临期</div>
        <div style="font-size: 0.32rem;color: #fff;margin-right: 0.1rem;" @click="jumpjianlouList">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="tag_list">
        <van-swipe class="my-swipe" :autoplay="false" :show-indicators="true">
          <van-swipe-item v-for="(jianlou,index_jianlou) in jianlouList" :key="index_jianlou"
                          style="margin-bottom: 0.3rem;"
          >
            <div class="flex-r-sa-c" style="flex-wrap: wrap;">
              <van-col v-for="(jianlou_item,index_jianlou_item) in jianlou" :key="index_jianlou_item" :span="8">
                <div class="tag281_item flex-c-c-c" @click.stop="jumpjianlou(jianlou_item)">
                  <van-image width="2.67rem" height="2.67rem" :src="jianlou_item.saleIconUrl" />
                  <div class="item_info van-multi-ellipsis--l2">
                    <div class="name van-multi-ellipsis--l2">{{ jianlou_item.saleName }}</div>
                    <!-- <div class="model van-ellipsis">{{ jianlou_item.proBrand }} | {{ jianlou_item.proModel }}</div> -->
                  </div>
                  <div class="item_price flex-r-sb-c">
                    <div class="price"><span style="font-size: 0.21rem;">￥</span>{{ jianlou_item.salePrice }}</div>
                    <van-icon name="shopping-cart" size="0.5rem" color="rgb(7, 103, 171)"
                              @click.stop="toCart(jianlou_item)"
                    />
                  </div>
                </div>
              </van-col>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

    <!-- 商品列表区域 -->
    <div class="index_pro_list">
      <!-- <van-tabs v-model="active" class="pro_top_type" animated swipeable @change="swipeablePro"> -->
      <van-tabs v-model="active" class="pro_top_type">
        <van-tab name="CREATE_TIME">
          <template #title>
            <div :class="proSearch.orderType === 'CREATE_TIME'?'type_name type_actived flex-r-c-c':'type_name flex-r-c-c'"
                 @click="proTypeSer('CREATE_TIME')"
            >
              <van-image width="0.53rem" height="0.53rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/new1.png"
              />
              <!-- <van-image v-if="proSearch.orderType === 'CREATE_TIME'" width="0.53rem" height="0.53rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/new1.png"
              />
              <van-image v-else width="0.53rem" height="0.53rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/new0.png"
              /> -->
              <span style="margin-left: 0.1rem;">新品专区</span>
            </div>
          </template>
          <div>
            <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
              <template #default="slotProps">
                <proCard01 :info="slotProps.item" />
              </template>
            </MyscrollList>
          </div>
        </van-tab>
        <van-tab name="brand">
          <template #title>
            <div :class="proSearch.proBrand === '贝思倍健'?'type_name type_actived flex-r-c-c':'type_name flex-r-c-c'"
                 @click="proTypeSer('BROWSE_NUM')"
            >
              <van-image width="1.54rem" height="0.61rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/logo_brnd.png"
                         class="index_tag" style="text-align: center;position: inherit;"
              />
            </div>
          </template>
          <div>
            <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
              <template #default="slotProps">
                <proCard01 :info="slotProps.item" />
              </template>
            </MyscrollList>
          </div>
        </van-tab>
        <!-- <van-tab name="CREATE_TIME">
          <template #title>
            <div :class="proSearch.orderType === 'CREATE_TIME'?'type_name type_actived flex-r-c-c':'type_name flex-r-c-c'"
                 @click="proTypeSer('CREATE_TIME')"
            >
              <van-image v-if="proSearch.orderType === 'CREATE_TIME'" width="0.8rem" height="0.48rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/index_tag_0.png"
                         class="index_tag"
              />
              <van-image v-else width="0.8rem" height="0.48rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/index_tag_1-1.png"
                         class="index_tag"
              />
              零售创收
            </div>
          </template>
          <div>
            <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
              <template #default="slotProps">
                <proCard01 :info="slotProps.item" />
              </template>
            </MyscrollList>
          </div>
        </van-tab> -->
        <van-tab name="CREATE_CLASSFLY">
          <template #title>
            <div :class="proSearch.orderType === 'CREATE_CLASSFLY'?'type_name type_actived flex-r-c-c':'type_name flex-r-c-c'"
                 @click="proTypeSer('CREATE_CLASSFLY')"
            >
              <van-image width="0.51rem" height="0.51rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/classfly1.png"
              />
              <!-- <van-image v-if="proSearch.orderType === 'CREATE_CLASSFLY'" width="0.51rem" height="0.51rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/classfly1.png"
              />
              <van-image v-else width="0.51rem" height="0.51rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/classfly0.png"
              /> -->
              <span style="margin-left: 0.1rem;">全部分类</span>

            </div>
            <!-- <div style="font-size: 0.32rem;color: #999999;">高效采购直达</div> -->
          </template>
          <div>
            <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
              <template #default="slotProps">
                <proCard01 :info="slotProps.item" />
              </template>
            </MyscrollList>
          </div>
        </van-tab>
      </van-tabs>

    </div>
    <!-- 底部留白 -->
    <div class="bottom_blank" />
    <!-- 底部滚动通知 -->
    <div class="home_noticeBar">
      <van-notice-bar left-icon="volume-o" :text="notice_bar[0].name" mode="closeable"
                      @click="noticejumpUrl(notice_bar[0].imageValue)"
      />
    </div>
    <!-- Tabber -->
    <Tabber ref="tabber" :index="1" />
    <!-- 资质认证弹窗 -->
    <!-- <van-dialog v-model="certificationShow" :show-confirm-button="false">
      <div class="certification_dialog flex-c-c-c">
        <van-image width="4.11rem" height="2.75rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification.png"
        />
        <div class="certification_title"> 您尚未获得认证资格 </div>
        <div class="certification_tip"> 暂时无法采购 </div>
        <div class="flex-r-sa-c">
          <van-button class="certification_btn" round type="info" color="#0767ab" plain style="margin-right: 0.5rem;"
                      @click="loginOut"
          >重新登录 </van-button>
          <van-button class="certification_btn" round type="info" color="#0767ab" @click="toUpCertification">立即认证
          </van-button>
        </div>
      </div>
    </van-dialog> -->
    <!-- 广告营销 -->
    <van-dialog v-model="adsDialog" :show-confirm-button="false" style="width:7.09rem;background:none">
      <div v-if="adsDialog" class="adsDialog_box flex-c-c-c">
        <van-icon class="adsDialog_box_close" name="close" @click="adsDialog = false" />
        <van-image class="vipGetDialog_btn" width="100%" height="100%" :src="adsItem[0].imageUrl" />
      </div>
    </van-dialog>
    <!-- vip 领取提示 -->
    <van-dialog v-model="vipGetDialog" :show-confirm-button="false" style="width:7.09rem">
      <div class="vipGetDialog_box ">
        <van-icon class="vipGetDialog_close" name="close" @click="getVipClose" />
        <van-image class="vipGetDialog_btn" width="5.07rem" height="1.45rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vipGetDialog_btn.png"
                   @click="getVipCard"
        />
      </div>
    </van-dialog>
    <!-- vip 领取提示  15-->
    <van-dialog v-model="vip15GetDialog" :show-confirm-button="false" style="width:7.09rem" @closed="closedVip15">
      <div class="vip15GetDialog_box ">
        <van-icon class="vipGetDialog_close" name="close" @click="vip15GetDialog = false" />
        <div class="vip15GetDialog_level">{{ vip15Info.mallVipLevelName }}用户，您好</div>
        <div class="vip15GetDialog_msg">- 本月下单可享受以下权益 -</div>
        <div style="padding: 0 0.1rem;">
          <van-row type="flex" justify="space-around">
            <van-col v-for="(vip15,vip15_index) in vip15List" :key="vip15_index" span="8">
              <div class="flex-c-c-c" style="margin-top: 0.43rem;">
                <van-image width="0.916rem" height="0.91rem"
                           :src="`https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vip_15_btn0${vip15.icon}.png`"
                />
                <div v-if="vip15.icon === '4' " class="vip_auth_title">
                  {{ vip15.title }}{{ vip15Info.discount === 100 ? '折扣':(vip15Info.discount/10 + '折') }}
                </div>
                <div v-else class="vip_auth_title">{{ vip15.title }}</div>
                <div class="vip_auth_msg">{{ vip15.msg }}</div>
              </div>
            </van-col>
            <van-col v-for="(auth,auth_index) in 6 - vip15List.length" :key="auth_index" span="8" />
          </van-row>
        </div>
        <van-image class="vipGetDialog_btn" width="5.07rem" height="1.45rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vip_15_btn.png"
                   @click="vip15GetDialog = false"
        />
      </div>
    </van-dialog>
    <!-- 直播引导 -->
    <div v-if="showLive" class="navtive_video flex-c-c-c">
      <van-image v-if="liveList[0]" width="2.8rem" height="3.3rem" :src="liveList[0].imageUrl" @click="jumpLive" />
      <van-icon v-if="liveList[0]" name="clear" size="0.6rem" style="opacity: 0.7;" @click="closeLive" />
    </div>
  </div>
</template>

<script>
import './index.scss'
import Tabber from '@/components/tabber'
import MyscrollList from '@/components/MyscrollList'
import proCard01 from '@/components/pro_card_01'

import { classifyBrand, vip15Auth, svip15Auth } from '@/utils/constants'
import {
  home_mallFlash, mallSaleModule_list, mallProduct_searchMallProduct, todayFirstLoginJudge,
  mallSaleModule_imageList, freshmanActivity, productModule_list, productModule_productList
} from '@/api/home'
import { mallProduct_hotSearch, mallProduct_tagList } from '@/api/pro'
import { user_certificationDecide, logOut } from '@/api/user'
import { homePopup, receiveAutomatic, homePopupDays } from '@/api/vip'
import { shoppingCart_addUnion } from '@/api/cart'
export default {
  name: 'Index',
  components: { Tabber, MyscrollList, proCard01 },
  data() {
    return {
      // certificationShow: false, // 资质认证
      certificationDecide: null,
      searchValue: '',
      hotList: [],
      info: {}, // banner，discount
      active: 'SALE_NUM',
      // 商品列表
      proSearch: { pageNum: 0, pageSize: 10, proName: '', orderType: 'CREATE_TIME' },
      proList: [],
      listLoading: false,
      // 秒杀
      scekillL: null,
      // 捡漏专区
      jumpjianlouId: '',
      jianlouList: [],
      // 新人特惠
      freshmanList: [],
      freshmanFlag: false,
      // 商品分类
      classFlyList: [],
      // vipGetDialog
      vipGetDialog: false,
      vip15GetDialog: false,
      vip15Info: {},
      vip15List: [],
      // tageIdList 275 严选-零售创收,281 严选-实惠自用,282 超值通货
      tageIdList275: [],
      tageIdList281: [],
      tageIdList285: [],
      // 直播引导
      liveList: [],
      showLive: false,
      // 跑马灯
      notice_bar: [],
      // 首次广告
      adsDialog: false,
      adsItem: []

    }
  },
  created() {
    console.log('created createdindex')
    this.loadHot()
    this.getCertificationDecide()
    this.seckillList()
    this.loadSaleModule()
    this.freshmanInfo()
    this.getTagIdActiveList(275)
    this.getTagIdActiveList(281)
    this.getTagIdActiveList(282)
    this.$utils.cartNum(this.$store)
    this.freshmanFlag = JSON.parse(localStorage.getItem('USER_INFO')).freshmanFlag
    // sessionStorage.setItem('jump', true)
  },
  activated() {
    console.log('keepalive 进入index')
    if (sessionStorage.getItem('tabberJump')) {
      this.$toast.loading({ message: '数据更新中...', forbidClick: true, loadingType: 'spinner', duration: 1000 })
      // location.reload()
      // this.$router.go(0)
      window.location.reload()
      sessionStorage.removeItem('tabberJump')
      // this.loadHot()
      // this.getCertificationDecide()
      // this.seckillList()
      // this.loadSaleModule()
      // this.freshmanInfo()
      this.$utils.cartNum(this.$store)
      this.freshmanFlag = JSON.parse(localStorage.getItem('USER_INFO')).freshmanFlag
    }
    // this.getCertificationDecide()
  },
  deactivated() {
    console.log('keepalive 离开index')
    // sessionStorage.removeItem('tabberJump')
  },
  methods: {
    tag_ser_click(item) {
      this.$router.push('/product/searchproList?name=' + item.search)
      // if (item.search === '贝思倍健') {
      //   this.$router.push('/product/subjectPage_bsbj')
      // } else {
      //   this.$router.push('/product/searchproList?name=' + item.search)
      // }
    },
    jumClassfly() {
      sessionStorage.setItem('tabberJump', true)
      this.$router.push('/product/classify')
    },
    loginOut() {
      logOut().then(res => { this.$utils.localLogout(this) })
    },
    // 15天vip
    get15VipProp() {
      homePopupDays().then(res => {
        this.vip15Info = res.data
        if (res.data.mallVipLevelId < 99) {
          if (res.data.mallVipLevelId !== 1) {
            for (const i in vip15Auth) { if (vip15Auth[i].icon === '3') { vip15Auth.splice(i, 1) } }
            this.vip15List = vip15Auth
          } else { this.vip15List = vip15Auth }
        } else { this.vip15List = svip15Auth }
        this.$nextTick(() => {
          this.vip15GetDialog = res.data.popUpStatus === 1
          if (res.data.popUpStatus !== 1) { this.checkFirstLogin() }
        })
      })
    },
    // vip弹框
    getVipProp() {
      homePopup().then(res => {
        if (res.data.popUpStatus === 1) { this.vipGetDialog = true } else { this.get15VipProp() }
      })
    },
    // 领取
    getVipCard() {
      receiveAutomatic().then(res => {
        this.vipGetDialog = false
        this.$router.push('/user/my')
        this.$toast({ message: '领取成功！', duration: 1 * 1000 })
      })
    },
    // 关闭VIP弹框
    getVipClose() {
      this.vipGetDialog = false
      this.checkFirstLogin()
    },
    closedVip15() { this.checkFirstLogin() },
    // 判断是否首次登录
    checkFirstLogin() {
      todayFirstLoginJudge().then(res => {
        if (this.adsItem) { this.adsDialog = res.data }
      })
    },
    // 获取推荐标签
    getTagsList(tagName) {
      mallProduct_tagList({ pageNum: 1, pageSize: 10, tagName: tagName }).then(res => {
        this.$set(this.proSearch, 'tagId', res.data.data[0].tagId)
      })
    },
    // 加载热门搜索
    loadHot() { mallProduct_hotSearch().then(res => { this.hotList = res.data.list }) },
    // 用户进入首页判断是否有资质
    getCertificationDecide() {
      this.showLive = parseInt(sessionStorage.getItem('liveFlag')) === 1
      user_certificationDecide().then(res => {
        this.certificationDecide = res.data.certificationDecide
        // this.certificationShow = res.data.certificationDecide !== 1
        if (res.data.certificationDecide === 1) {
          this.getVipProp()
        }
      })
    },
    // 去认证
    toUpCertification() {
      if (this.certificationDecide === -1) { this.$router.push('/home/certification') }
      if (this.certificationDecide === 0) { this.$router.push('/publictips/success?type=1') }
      if (this.certificationDecide === 2) { this.$router.push('/publictips/success?type=2') }
    },
    // 获取banner，discount
    // getData() { common_home().then(res => { this.info = res.data }) },
    // 获取推荐的商品
    // type SALE_NUM:超值通货 BROWSE_NUM:严选-实惠自用 CREATE_TIME:严选-零售创收
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      mallProduct_searchMallProduct(this.proSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        // if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
        if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        // }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },
    // 商品顶部三个type查询
    proTypeSer(type) {
      if (type === 'CREATE_CLASSFLY') {
        this.proSearch.orderType = 'CREATE_CLASSFLY'
        this.$router.push('/product/classify')
      }
      if (type === 'CREATE_TIME') {
        this.proSearch.proBrand = ''
        this.proSearch.orderType = 'CREATE_TIME'
        this.proList = []
        this.proSearch.pageNum = 1
        this.$nextTick(() => { this.getProList() })
      }
      if (type === 'SALE_NUM') {
        this.proSearch.proBrand = ''
        this.proSearch.orderType = 'SALE_NUM'
        this.proList = []
        this.proSearch.pageNum = 1
        this.$nextTick(() => { this.getProList() })
      }
      if (type === 'BROWSE_NUM') {
        this.proSearch.orderType = ''
        this.proSearch.proBrand = '贝思倍健'
        this.proList = []
        this.proSearch.pageNum = 1
        this.$nextTick(() => { this.getProList() })
      }
    },
    // 侧滑
    // swipeablePro(e) { this.proTypeSer(e) },
    // 活动 banner 跳转事件
    jumpUrl(url) { window.open(url) },
    noticejumpUrl(url) { window.open(url) },
    // 分类 品牌 跳转
    classifyJump(index) { this.$router.push('/product/classifyList?title=' + classifyBrand[index].title + '&id=' + classifyBrand[index].id) },
    // 秒杀列表
    seckillList() {
      home_mallFlash({ pageSize: 9, pageNum: 1, isNow: 1 }).then(res => {
        var startDate1 = new Date().getTime()
        var endDate1 = new Date(res.data.data[0].endDate.replace(/-/g, '/')).getTime()
        this.$set(res.data.data[0], 'time', (endDate1 - startDate1))
        this.scekillL = res.data.data
      })
    },
    // 秒杀跳转
    scekillJump(item) { this.$router.push('/product/detail?productId=' + item.productId) },
    // 新人活动
    freshmanInfo() {
      freshmanActivity({ pageNum: 1, pageSize: 9999 }).then(res => {
        this.freshmanList = res.data.data
      })
    },
    stockTo(item) { this.$router.push('/product/detail?productId=' + item.productId + '&timeLimitActivityType=3') },
    // 营销模块列表加载
    loadSaleModule() {
      mallSaleModule_list({ pageNum: 1, pageSize: 9999 }).then(res => {
        this.saleModuleList = res.data.data
        this.turnSaleItems()
      })
    },
    // 轮询加载营销模块
    turnSaleItems() {
      this.getModelList()
      for (const i in this.saleModuleList) {
        if (this.saleModuleList[i].isOpen === 1) {
          this.loadItem(this.saleModuleList[i])
        }
      }
    },
    loadItem(info) {
      mallSaleModule_imageList({ pageNum: 1, pageSize: 9999, mallSaleModuleId: info.mallSaleModuleId }).then(res => {
        if (info.mallSaleModuleId === '1686919882362458112') { // 直播引导
          this.liveList = res.data.data
        }
        if (info.mallSaleModuleId === '1782953611651923968') { // 首次登录广告
          this.adsItem = res.data.data
        }
        if (info.mallSaleModuleId === '1710831457259438080') { // 跑马灯
          this.notice_bar = res.data.data
        }
        if (info.mallSaleModuleId === '1583032697968791552') { // 商城banner
          this.info.banners = res.data.data
        }
        if (info.mallSaleModuleId === '1580458875482673152') { // 商品分类
          this.classFlyList = res.data.data
        }
        if (info.mallSaleModuleId === '1583047440733442048') { // 精选品牌
          this.brandList = res.data.data
        }
        if (info.mallSaleModuleId === '1580459238285774848') { // 精选活动
          this.info.discount = res.data.data
        }
        if (info.mallSaleModuleId === '1583020166290214912') { // 爆款推荐
          var index = 0
          var count = 3
          var arrTemp = []
          var experts = res.data.data
          for (var i = 0; i < res.data.data.length; i++) {
            index = parseInt(i / count)
            if (arrTemp.length <= index) {
              arrTemp.push([])
            }
            arrTemp[index].push(experts[i])
          }
          console.log(',arrTemp', arrTemp)
          this.baokuanList = arrTemp
        }
      })
    },
    // 营销模块的跳转事件
    jumpSale(item) {
      if (item.imageType === 1) { window.open(item.imageValue) }
      if (item.imageType === 2) {
        sessionStorage.setItem('listJump', true)
        this.$router.push('/product/classifyList?title=' + item.name + '&id=' + item.imageValue)
      }
      if (item.imageType === 3) { this.$router.push('/product/detail?productId=' + item.imageValue) }
      if (item.imageType === 4) {
        sessionStorage.setItem('listJump', true)
        this.$router.push('/product/brandList?name=' + item.imageValue) // 品牌精选
      }
      if (item.imageType === 5) {
        this.$router.push(item.imageValue)
      }
    },
    // 捡漏专区
    // 获取销售模块列表 --> 加载模块下商品
    getModelList() {
      productModule_list({ pageNum: 1, pageSize: 99 }).then(res => {
        this.jumpjianlouId = res.data.data[0].mallProductModuleId
        productModule_productList({ pageNum: 1, pageSize: 9, mallProductModuleId: res.data.data[0].mallProductModuleId }).then(resx => {
          var index = 0
          var count = 3
          var arrTemp = []
          var experts = resx.data.data
          for (var i = 0; i < resx.data.data.length; i++) {
            index = parseInt(i / count)
            if (arrTemp.length <= index) {
              arrTemp.push([])
            }
            arrTemp[index].push(experts[i])
          }
          console.log(',arrTemp', arrTemp)
          this.jianlouList = arrTemp
          // this.jianlouList = resx.data.data
        })
      })
    },
    // tagId 标签下的活动版块 id --> 275 严选-零售创收,281 严选-实惠自用,282 超值通货
    getTagIdActiveList(id) {
      var parms = {}
      if (id === 275 || id === 281) parms = { pageNum: 1, pageSize: 18, tagId: id }
      if (id === 282) parms = { pageNum: 1, pageSize: 12, tagId: id }
      mallProduct_searchMallProduct(parms).then(res => {
        // 4
        var index4 = 0; var count4 = 4; var arrTemp4 = []; var experts4 = res.data.data
        for (var i = 0; i < res.data.data.length; i++) {
          index4 = parseInt(i / count4)
          if (arrTemp4.length <= index4) { arrTemp4.push([]) }
          arrTemp4[index4].push(experts4[i])
        }
        //  6
        var index6 = 0; var count6 = 6; var arrTemp6 = []; var experts6 = res.data.data
        for (var j = 0; j < res.data.data.length; j++) {
          index6 = parseInt(j / count6)
          if (arrTemp6.length <= index6) { arrTemp6.push([]) }
          arrTemp6[index6].push(experts6[j])
        }
        if (id === 275) { this.tageIdList275 = arrTemp6 }
        if (id === 281) { this.tageIdList281 = arrTemp6 }
        if (id === 282) { this.tageIdList285 = arrTemp4 }
      })
    },
    // 加入购物车
    toCart(item) {
      const parms = { companyId: '1000', products: [{ productId: item.productId, proNumber: item.minQuantity || 1 }] }
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
      })
    },
    // 跳转直播
    jumpLive() { window.open(this.liveList[0].imageValue) },
    // 关闭直播提示
    closeLive() { this.showLive = false; sessionStorage.setItem('liveFlag', 2) },
    jumpTagList(id) { this.$router.push('/product/tagIdProList?tagId=' + id) },
    jumpjianlou(item) { this.$router.push('/product/detail?productId=' + item.productId) },
    jumpjianlouList() { this.$router.push('/product/activerProList?id=' + this.jumpjianlouId + '&type=0') },
    jumpbaokuanList() { this.$router.push('/product/activerProList?id=' + this.jumpjianlouId + '&type=1') }
  }
}
</script>
